import React from 'react'
import { hydrate, render } from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

import './assets/style/main.scss'
import './mockMediaQuery'

const rootElement = document.getElementById('root')
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement)
} else {
  render(<App />, rootElement)
}

// fromEvent(window, 'resize')
//   .pipe(throttle(() => interval(400)))
//   .subscribe(() => {
//     const vh = window.innerHeight * 0.01
//     document.documentElement.style.setProperty('--vh', `${vh}px`)
//   })

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
