import React from 'react'
import { Helmet } from 'react-helmet'
const rootUrl = 'https://www.childrenof.vn'

type MetaInfoProps = {
  readonly metaInfo: {
    readonly title?: string
    readonly description?: string
  }
}

const MetaInfo: React.FC<MetaInfoProps> = React.memo(({ metaInfo }) => {
  const { title, description } = metaInfo

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={rootUrl} />
      <meta property="og:image" content={`${rootUrl}/children-of-logo.png`} />
      <meta property="description" content={description} />
    </Helmet>
  )
})

MetaInfo.displayName = 'MetaInfo'

export default MetaInfo
