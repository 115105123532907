import React, { memo, ReactElement, ReactNode } from 'react'
import { useSpring, animated } from 'react-spring'
import { useMeasure, usePrevious } from '../../hooks'
import { Content } from './styles'

type Props = {
  name: ReactElement | string
  style?: React.CSSProperties
  isOpen: boolean
  children?: ReactNode
  className?: string
  onClick?: () => void
}

const Subtree: React.FC<Props> = ({ children, name, style, className = '', onClick = undefined, isOpen }) => {
  const previous = usePrevious(isOpen)
  const [{ ref }, { height: viewHeight }] = useMeasure()
  const [{ ref: btnRef }, { height: btnHeight }] = useMeasure()
  const config = { mass: 2.5, tension: 170, friction: 40 }
  const spring = useSpring({
    config,
    from: { height: 0, opacity: 0, transform: 'translate3d(0,20p,0)' },
    to: {
      height: isOpen ? viewHeight : 0,
      opacity: isOpen ? 1 : 0,
      transform: `translate3d(0,${isOpen ? 0 : 0}px,0)`,
      padding: isOpen ? '1.3rem 1rem' : '0rem 1rem',
    },
    delay: 10,
  })
  const titleSpring = useSpring({
    config,
    to: {
      height: isOpen ? 0 : btnHeight,
      padding: isOpen ? '0px 0px' : '1rem 0px',
      opacity: isOpen ? 0 : 1,
      transform: `translate3d(0,${isOpen ? 0 : 0}px,0)`,
    },
    from: { height: 0, padding: '0px 0px', opacity: 1, transform: 'translate3d(0,20p,0)' },
  })
  return (
    <div style={style} className={className} onClick={onClick} onTouchEnd={onClick}>
      <animated.div
        style={{
          textAlign: 'center',
          ...titleSpring,
          height: !(isOpen && previous === isOpen) ? 'auto' : titleSpring['height'],
          padding: !isOpen && previous === isOpen ? 15 : titleSpring['padding'],
        }}
        ref={ref}
        className="menu-title"
      >
        {name}
      </animated.div>
      <Content
        style={{
          opacity: spring['opacity'],
          padding: spring['padding'],
          height: isOpen && previous === isOpen ? 'auto' : spring['height'],
          maxHeight: '80vh',
        }}
      >
        <animated.div className="menu-content" style={{ transform: spring['transform'] }} ref={btnRef}>
          {children}
        </animated.div>
      </Content>
    </div>
  )
}

export default memo(Subtree)
