import React from 'react'
import { useSpring, animated } from 'react-spring'

type ThreeDotsProps = {
  isExpanded: boolean
  isPlaying: boolean
}

const ThreeDots: React.FC<ThreeDotsProps> = ({ isExpanded, isPlaying }) => {
  const topProps = useSpring({ marginTop: !isExpanded ? '15px' : `13px` })
  const middleProps = useSpring({ marginTop: !isExpanded ? '5px' : '7px' })
  const bottomProps = useSpring({ marginTop: !isExpanded ? '5px' : '7px' })
  return (
    <span className={`ellipsis ${isPlaying ? 'transparent' : ''}`} style={{ left: 0, position: 'absolute', top: 0 }}>
      <animated.div style={topProps} className="dot" />
      <animated.div style={middleProps} className="dot" />
      <animated.div style={bottomProps} className="dot" />
    </span>
  )
}

export default ThreeDots
