import React from 'react'
import { useParams, useHistory } from 'react-router'
import Work from '../components/Work'

type WorkParams = {
  id: string
}

const SelectedWork: React.FC = () => {
  const { id } = useParams<WorkParams>()
  const history = useHistory()
  return (
    <div>
      <Work
        id={id}
        status="open"
        open={() => {
          /*comment*/
        }}
        close={() => history.push('/')}
        poster=""
      />
    </div>
  )
}

export default SelectedWork
