import React from 'react'
import { useReactiveVar } from '@apollo/client'
import { isShowReelState } from '../data/local'
import { Reel } from '../components'

const LazyWorks = React.lazy(() => import('./Works'))

const Home: React.FC = () => {
  const isShowingReel = useReactiveVar(isShowReelState)
  return (
    <div className="home" style={{ overflow: isShowingReel ? 'hidden' : 'scroll' }}>
      <Reel />
      <React.Suspense fallback="<>">
        <LazyWorks />
      </React.Suspense>
    </div>
  )
}

export default Home
