/* eslint-disable @typescript-eslint/no-empty-function */
import React, { forwardRef, useState } from 'react'
import { a } from 'react-spring'
import { isPlayingState } from '../../data/local'
import { useReactiveVar } from '@apollo/client'
//
import PlayButton from '../PlayButton'
//
import './style.scss'

export type Props = {
  defaultlink: string
  mobilelink?: string
  portrait?: boolean
  className?: string
  height?: string
  style?: any
  controls?: boolean
  onEnded?: React.EventHandler<React.SyntheticEvent<HTMLVideoElement, Event>>
  poster?: string
  autoPlay?: boolean
  onClick?: React.MouseEventHandler
  muted?: boolean
  disableTapToPlay?: boolean
  playbutton?: boolean
  preload?: string
  reel?: boolean
}

export type Ref = HTMLVideoElement

const VideoPlayer = forwardRef<Ref, Props>(function VideoPlaya({ autoPlay = true, poster, ...props }, ref) {
  const [dragging, setDragging] = useState<boolean>(false)
  const isPlaying = useReactiveVar(isPlayingState)
  const tapToPlayPause = async (e: any) => {
    if (dragging || props.disableTapToPlay) return
    const ref2 = ref as React.MutableRefObject<HTMLVideoElement>
    e.preventDefault()
    if (ref2.current && ref2.current.paused) {
      try {
        const playPromise = await ref2.current.play()
        console.log({ playPromise })
      } catch (e) {
        console.log({ e })
      }
    } else {
      ref2.current.pause()
    }
    setDragging(false)
  }
  let videoLink = props.defaultlink
  if (window.innerWidth < 555 && props.mobilelink) videoLink = props.mobilelink
  return (
    <a.div
      style={props.style}
      className={props.className + ' video-container'}
      onClick={tapToPlayPause}
      onTouchStart={() => setDragging(false)}
      onTouchMove={() => setDragging(true)}
      onTouchEnd={tapToPlayPause}
    >
      <video
        id={props.reel ? 'reel' : ''}
        className={props.className + ' video'}
        controls={'controls' in props ? props.controls : true}
        playsInline
        onPlaying={(): boolean => isPlayingState(true)}
        onPause={(): boolean => isPlayingState(false)}
        onEnded={(e): void => {
          isPlayingState(false)
          props.onEnded && props.onEnded(e)
        }}
        poster={poster}
        style={{ height: '100%', width: '100%', objectFit: 'cover' }}
        ref={ref}
        key={videoLink}
      >
        <source src={videoLink} type="video/mp4" />
      </video>
      {props.playbutton && props.reel && <PlayButton isPlaying={isPlaying} />}
    </a.div>
  )
})

export default VideoPlayer
