/* eslint-disable @typescript-eslint/camelcase */
import React from 'react'
import Chance from 'chance'
import Work from './Work'
import Fullscreen from './Fullscreen'
import { GetAllProjects_allProjects } from '../data/__generated__/GetAllProjects'
import { GetProjectDetail_project_behindTheScenePhotos } from '../data/__generated__/GetProjectDetail'

const chance = new Chance()

export const pcVwWithPadding = (pd) => (x) => `calc(((100vw - ${pd * 2}px) / 100 ) * ${x})`

type HasVertical = {
  vertical: boolean
}

type HasPhoto =
  | (GetAllProjects_allProjects & HasVertical)
  | (GetProjectDetail_project_behindTheScenePhotos & HasVertical)
const isBtsPhoto = (c: HasPhoto): c is GetProjectDetail_project_behindTheScenePhotos & HasVertical => 'url' in c
const isContent = (c: HasPhoto): c is GetAllProjects_allProjects & HasVertical => 'posterImage' in c

const VideoOrImageElement = ({ content, style }: { content: HasPhoto; style: React.CSSProperties }) => {
  return isContent(content) ? (
    <Fullscreen className="fill-parent" key={content.title} style={style}>
      {({ open, close, status }): React.ReactNode => (
        <Work id={content.id} status={status} close={close} open={open} poster={content.posterImage.url} />
      )}
    </Fullscreen>
  ) : (
    <img
      src={content.url}
      loading="lazy"
      className="poster"
      style={{ position: 'absolute', objectFit: 'scale-down', ...style }}
    />
  )
}

interface DuoProps {
  contents: [HasPhoto, HasPhoto]
}

interface MonoProps {
  content: HasPhoto
}

interface VwOverridable {
  vw: (x: number) => string
}

type DuoBlock = React.FC<DuoProps & VwOverridable>
type MonoBlock = React.FC<MonoProps & VwOverridable>
type Block = DuoBlock | MonoBlock

const BlockFull: MonoBlock = ({ content, vw }) => (
  <div style={{ height: vw(64.2), width: vw(100) }} className="full block">
    <VideoOrImageElement
      content={content}
      style={{
        height: vw(56.25),
        width: vw(100),
        objectFit: 'cover',
      }}
    />
  </div>
)

const BlockHero: MonoBlock = ({ content, vw }) => (
  <div style={{ height: vw(52.2), width: vw(100) }} className="hero block">
    <VideoOrImageElement
      content={content}
      style={{
        left: '50%',
        height: vw(39.37),
        width: vw(70),
        transform: 'translate(-50%, 0)',
      }}
    />
  </div>
)

const BlockDuoAsym: DuoBlock = ({ contents: [content1, content2], vw }) => (
  <div style={{ height: vw(46.5), width: vw(100) }} className="duo-asym block">
    <VideoOrImageElement content={content1} style={{ width: vw(49.5), height: vw(27.8), left: 0, top: vw(4) }} />
    <VideoOrImageElement content={content2} style={{ right: 0, width: vw(39.7), height: vw(22.4), top: 0 }} />
  </div>
)

const BlockMonoRandom: MonoBlock = ({ content, vw }) => {
  const frameWidth = 32.7
  const left = ((100 - frameWidth) * chance.floating({ min: 0, max: 1 })) / 2
  return (
    <div style={{ height: vw(45.4), width: vw(100) }} className="mono block">
      <VideoOrImageElement content={content} style={{ width: vw(58.11), height: vw(32.7), left: vw(left) }} />
    </div>
  )
}

const BlockDuoSymAlt: DuoBlock = ({ contents: [content1, content2], vw }) => (
  <div style={{ height: vw(54.7), width: vw(100) }} className="dyo-sym-alt block">
    <VideoOrImageElement content={content1} style={{ width: vw(45.4), height: vw(25.5) }} />
    <VideoOrImageElement content={content2} style={{ width: vw(45.4), height: vw(25.5), right: 0, top: vw(16.5) }} />
  </div>
)

const BlockVertical: DuoBlock = ({ contents: [content1, content2], vw }) => (
  <div style={{ height: vw(69.2), width: vw(100) }} className="vertical block">
    <VideoOrImageElement content={content1} style={{ width: vw(56.5), height: vw(31.8) }} />
    <VideoOrImageElement
      content={content2}
      style={{ height: vw(56.5), width: vw(31.8), right: 0, objectPosition: '0 0', backgroundColor: 'transparent' }}
    />
  </div>
)

const BlockHeroLeft: MonoBlock = ({ content, vw }) => (
  <div style={{ height: vw(52.2), width: vw(100) }} className="hero-left block">
    <VideoOrImageElement content={content} style={{ left: vw(5.4), height: vw(39.6), width: vw(70) }} />
  </div>
)

const BlockVerticalSwap: DuoBlock = ({ contents: [content1, content2], vw }) => (
  <div style={{ height: vw(69.2), width: vw(100) }} className="vertical-swap block">
    <VideoOrImageElement content={content1} style={{ width: vw(56.5), height: vw(31.8), right: 0 }} />
    <VideoOrImageElement
      content={content2}
      style={{ height: vw(56.5), width: vw(31.8), objectPosition: '0 0', backgroundColor: 'transparent' }}
    />
  </div>
)

const BlockDuoSym: DuoBlock = ({ contents: [content1, content2], vw }) => (
  <div style={{ height: vw(40), width: vw(100) }} className="sym block">
    <VideoOrImageElement content={content1} style={{ height: vw(25.5), width: vw(45.4) }} />
    <VideoOrImageElement content={content2} style={{ height: vw(25.5), width: vw(45.4), right: 0 }} />
  </div>
)

const BlockDuoSymAltSwap: DuoBlock = ({ contents: [content1, content2], vw }) => (
  <div style={{ height: vw(54.7), width: vw(100) }} className="sym-alt-swap block">
    <VideoOrImageElement content={content1} style={{ width: vw(45.4), height: vw(25.5), top: vw(16.5) }} />
    <VideoOrImageElement content={content2} style={{ width: vw(45.4), height: vw(25.5), right: 0 }} />
  </div>
)

const BlockDuoAsymSwap: DuoBlock = ({ contents: [content1, content2], vw }) => (
  <div style={{ height: vw(42.5), width: vw(100) }} className="duo-asym-swap block">
    <VideoOrImageElement content={content1} style={{ width: vw(39.7), height: vw(22.4) }} />
    <VideoOrImageElement content={content2} style={{ right: 0, width: vw(49.5), height: vw(27.8), top: vw(4) }} />
  </div>
)

const allBlock: Block[] = [
  BlockHero,
  BlockDuoAsymSwap,
  BlockDuoAsym,
  BlockDuoSym,
  BlockDuoSymAlt,
  BlockDuoSymAltSwap,
  BlockHeroLeft,
  BlockMonoRandom,
]
const DuoBlocks: Block[] = [BlockDuoAsymSwap, BlockDuoAsym, BlockDuoSym, BlockDuoSymAlt, BlockDuoSymAltSwap]

const MonoBlocks: MonoBlock[] = [BlockHero, BlockHeroLeft, BlockMonoRandom]

export const ContentsBlock = ({
  contents,
  padding = 84,
  firstNotHero = false,
}: {
  contents: HasPhoto[]
  padding?: number
  firstNotHero?: boolean
}): React.ReactNode[] => {
  console.log({ contents })
  const result: React.ReactNode[] = []
  const vwOverride = (x) => ({ ...x, vw: pcVwWithPadding(padding) })
  let counter = 0
  let duoBlockCounter = 0
  let lastBlock: Block = BlockHero
  while (counter < contents.length) {
    let block = allBlock.filter((b) => b !== lastBlock)[chance.integer({ min: 0, max: allBlock.length - 2 })]
    const currentContent = contents[counter]
    const secondContent = contents[counter + 1]
    if (firstNotHero) block = DuoBlocks[chance.integer({ min: 0, max: DuoBlocks.length - 1 })]
    if (isBtsPhoto(currentContent) && currentContent.vertical && secondContent) {
      result.push(BlockVertical(vwOverride({ contents: [contents[counter + 1], currentContent] })))
      counter += 2
      duoBlockCounter += 1
      lastBlock = block
      continue
    } else if (secondContent && isBtsPhoto(secondContent) && secondContent.vertical) {
      result.push(BlockVerticalSwap(vwOverride({ contents: [currentContent, secondContent] })))
      counter += 2
      duoBlockCounter += 1
      lastBlock = block
      continue
    } else if (duoBlockCounter > 0) {
      block = MonoBlocks[chance.integer({ min: 0, max: MonoBlocks.length - 1 })]
      result.push((block as MonoBlock)(vwOverride({ content: currentContent })))
      counter += 1
      duoBlockCounter = 0
      lastBlock = block
      continue
    } else if (counter === 0 && !firstNotHero) {
      // first block always hero
      if (!isBtsPhoto(currentContent)) {
        result.push(BlockHero(vwOverride({ content: currentContent })))
      } else {
        result.push(BlockFull(vwOverride({ content: currentContent })))
      }
      counter += 1
      duoBlockCounter = 0
      lastBlock = block
      continue
    } else if (DuoBlocks.includes(block) && counter + 1 < contents.length && duoBlockCounter < 2) {
      // duoblock if there are enough content left
      result.push((block as DuoBlock)(vwOverride({ contents: [currentContent, contents[counter + 1]] })))
      counter += 2
      duoBlockCounter += 1
      lastBlock = block
      continue
    } else if (!DuoBlocks.includes(block)) {
      // mono block
      result.push((block as MonoBlock)(vwOverride({ content: currentContent })))
      counter += 1
      duoBlockCounter = 0
      lastBlock = block
      continue
    } else {
      // only 1 content left
      const monoBlock = MonoBlocks[chance.integer({ min: 0, max: MonoBlocks.length - 1 })]
      result.push(monoBlock(vwOverride({ content: currentContent })))
      counter += 1
      duoBlockCounter = 0
      lastBlock = block
      continue
    }
  }
  return result
}

type Props = {
  contents: HasPhoto[]
  firstNotHero?: boolean
  padding?: number
}

const BlockLayoutComponent: React.FC<Props> = ({ contents, firstNotHero, padding = 84 }) => (
  <div className="master" style={{ padding: `4rem ${padding}px 0px`, background: '#141414' }}>
    {React.useMemo(() => ContentsBlock({ contents, firstNotHero, padding }), [contents, firstNotHero])}
  </div>
)
export default BlockLayoutComponent
