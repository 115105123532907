/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react'
import { a } from 'react-spring'
import { useWheel, useScroll } from 'react-use-gesture'
import { useInView } from 'react-intersection-observer'
import classNames from 'classnames'
//
import '../assets/style/containers/works.scss'
//
import type { State } from './Fullscreen'
import PillButton from './PillButton'
import BackButton from './BackButton'
import { ContentsBlock } from './BlockLayoutComponent'
import VideoPlayer from './VideoPlayer'
import { useViewport } from '../hooks/ViewportContext'
import { useQuery } from '@apollo/client'
import { GET_PROJECT_DETAIL } from '../data/queries'
import { GetProjectDetail } from '../data/__generated__/GetProjectDetail'
import { isPlayingState } from '../data/local'
import { useLocale } from '@react-aria/i18n'
import { StructuredText } from 'react-datocms'
import { getVideo, unwrapCredits } from '../data/fetch'
import { useHistory } from 'react-router'

type Props = {
  id: string
  style?: any
  status: State
  close: () => void
  open: () => void
  poster: string
}

export type Ref = HTMLDivElement

const Work: React.FC<Props> = ({ id, style, status, close, open, poster }) => {
  const history = useHistory()
  const { locale } = useLocale()
  const { data } = useQuery<GetProjectDetail>(GET_PROJECT_DETAIL, { variables: { id, locale: locale.substring(0, 2) } })
  const { width, height } = useViewport()
  const isHorizontal = height < width
  const isMobile = width < 1000 && height < 1000
  const videoRef = React.useRef<HTMLVideoElement>()
  const [mainDivRef, inView] = useInView({ threshold: 0.01 })
  //
  const [autoplay, setAutoplay] = useState<boolean>(true)
  const [makingOf, setMakingOf] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)
  //
  const allClose = (): boolean => status === 'closed' || status === 'closing'
  const allOpen = (): boolean => status === 'open' || status === 'opening'
  // open & dismiss actions
  const closeWithEffect = () => {
    isPlayingState(false)
    setAutoplay(true)
    close()
    history.push('/')
  }
  React.useEffect(() => {
    if (isMobile && isHorizontal && makingOf) {
      setMakingOf(false)
    }
  }, [width, height, makingOf])
  const onClick: React.MouseEventHandler = (e) => {
    if (!(e.target instanceof HTMLElement || e.target instanceof HTMLVideoElement)) return e.preventDefault()
    if (allClose()) {
      if (e.target instanceof HTMLHeadingElement || e.target instanceof HTMLVideoElement) {
        open()
        history.push(`/works/${id}`)
      }
    }
    if (allOpen()) {
      if (
        e.target instanceof HTMLDivElement &&
        !(e.target.closest('.bts-content') || e.target.closest('.pill-container'))
      ) {
        if (!makingOf) {
          closeWithEffect()
        } else {
          setMakingOf(false)
        }
      }
    }
  }

  const scrollBind = useScroll(({ event }) => {
    const target = event.currentTarget
    if (target.scrollTop > 0) setProgress((target.scrollTop * 100) / (target.scrollHeight - window.innerHeight))
  })

  const body = document.querySelector('body') as HTMLElement
  const bodyUseWheel = useWheel(
    ({ event }) => {
      if (status !== 'closed' && !makingOf) return event.preventDefault()
    },
    {
      domTarget: body,
      eventOptions: { passive: false },
    },
  )
  React.useEffect(bodyUseWheel, [status, bodyUseWheel, makingOf])

  //
  const btsPhotos = (data && data.project && data.project.behindTheScenePhotos) || []
  const btsHighlightPhotos = btsPhotos.map((p) => ({
    ...p,
    isHighlight: p.customData.isHighlight === 'true',
  }))
  const mappedVertical = [...btsHighlightPhotos, ...btsPhotos].map((p) => ({ ...p, vertical: p.height > p.width }))
  const contentsBlock = React.useMemo<React.ReactNode>(
    () =>
      ContentsBlock({
        contents: mappedVertical,
        padding: (() => {
          return window.innerWidth / 2 - 550 // need to use function in case of resize
        })(),
      }),
    [data],
  )
  const images = () => {
    return mappedVertical.map((photo, id) => (
      <img key={id} src={photo.url} loading="lazy" style={{ width: '100%', padding: '30px 0px' }} className="photo" />
    ))
  }
  const isVertical = (): boolean => width < 1000
  if (status === 'open' && autoplay && videoRef.current && videoRef.current.paused) {
    videoRef.current.play()
    setAutoplay(false)
  }
  const vimeoLink = data ? data.project.mainVideo.url.replace('https://vimeo.com/', '') : ''
  const [vimeoData, setVimeoData] = useState<any>()
  useEffect(() => {
    getVideo(vimeoLink)
      .then((data) => setVimeoData(data))
      .catch((e) => console.log(e))
  }, [vimeoLink])
  const fileToUse = vimeoData && vimeoData.data.files.filter((f) => f.public_name === 'HD 720p')
  const mp4Link = fileToUse && fileToUse.length > 0 && fileToUse[0].link
  if (!data) return null
  const creditObj = unwrapCredits(data.project.credits)
  const finalCredit = Array.isArray(creditObj) ? creditObj : null
  return (
    <a.div
      className={classNames('work', status, {
        collapsed: makingOf,
        appear: inView,
      })}
      onClick={onClick}
      style={style}
      ref={mainDivRef}
    >
      <VideoPlayer
        defaultlink={mp4Link || data.project.mainVideo.url}
        poster={poster}
        autoPlay={false}
        controls={status === 'open' && !makingOf}
        onClick={onClick}
        ref={videoRef}
        disableTapToPlay={status !== 'open'}
        className={classNames('poster', { 'making-of': makingOf })}
        preload="none"
      />
      {(status === 'open' || status === 'closed') && (
        <div className={`bts-content ${makingOf ? 'collapsed' : ''}`} {...scrollBind()}>
          {makingOf && width > 555 && (
            <React.Fragment>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </React.Fragment>
          )}
          <h1
            className={classNames('title', { 'making-of': makingOf })}
            style={{ maxWidth: 'calc(100vw - 30px)', marginTop: status === 'open' && 19 }}
          >
            {status === 'closed' && data.project.title}
            {status === 'open' && !makingOf ? data.project.behindTheSceneTitle || data.project.title : null}
            {makingOf ? <StructuredText data={data.project.content} /> || data.project.behindTheSceneTitle : null}
          </h1>
          {allClose() && <h2 className="subtitle">{data.project.behindTheSceneTitle}</h2>}
          {makingOf && finalCredit && (
            <React.Fragment>
              {finalCredit.map((creditsGroup, id) => (
                <ul key={id} className="credits">
                  {Object.entries(creditsGroup).map(([title, name]) => (
                    <li className="credit" key={title}>
                      <h3 className="job">{title}</h3>
                      {Array.isArray(name) ? (
                        name.map((n) => (
                          <h3 key={n} className="name">
                            {n}
                          </h3>
                        ))
                      ) : (
                        <h3 className="name">{name}</h3>
                      )}
                    </li>
                  ))}
                </ul>
              ))}
            </React.Fragment>
          )}
          <div className="photos">
            {allOpen() && makingOf && <React.Fragment>{isVertical() ? images() : contentsBlock}</React.Fragment>}
          </div>
        </div>
      )}
      {allOpen() && (!isHorizontal || width > 1000) && (
        <PillButton
          onClick={(): void => {
            if (width > 1000 && videoRef.current) {
              makingOf ? videoRef.current.play() : videoRef.current.pause()
            }
            setMakingOf(!makingOf)
          }}
          makingOf={makingOf}
        />
      )}
      {allOpen() && makingOf && (
        <div id="progress-bar">
          <div className="progress" style={{ width: `${progress}%`, backgroundColor: '#35363a', borderRadius: 0 }} />
        </div>
      )}
      {allOpen() && <BackButton onClick={closeWithEffect} status={status} makingOf={makingOf} />}
    </a.div>
  )
}
export default Work
