import React from 'react'
import play from '../assets/img/play_arrow.svg'

type Props = {
  isPlaying: boolean
}

const PlayButton: React.FC<Props> = ({ isPlaying }) => (
  <div style={{ opacity: isPlaying ? 0 : 1 }} className="play-button">
    <img src={play} className="play-icon" />
  </div>
)

export default PlayButton
