import React, { useState } from 'react'
import { Portal } from 'react-portal'
import { useSpring, animated } from 'react-spring'
import useDimensions from 'react-use-dimensions'
import type { SpringConfig } from 'react-spring'
import { useWheel } from 'react-use-gesture'

import './Fullscreen.css'

type State = 'closed' | 'opening' | 'open' | 'closing'

const stackingPriority: Record<State, number> = {
  closed: 1001,
  closing: 1002,
  opening: 1003,
  open: 9998,
}

const fullScreenPosition = {
  left: 0,
  width: 'calc(0px + 100vw)',
  top: 0,
  height: 'calc(0px + 100vh)',
}

const getPositionFromBoundingRect = (boundingRect) => {
  return {
    left: boundingRect.top,
    width: `calc(${boundingRect.width}px + 0vw)`,
    top: boundingRect.left,
    height: `calc(${boundingRect.height}px + 0vh)`,
  }
}

const Fullscreen = (props) => {
  const [status, setStatus] = useState<State>('closed')
  const showExpanded = status !== 'closed'

  const open = (): void => setStatus('opening')
  const close = (): void => setStatus('closing')
  const handleRest = (): void => {
    if (status === 'opening') {
      setStatus('open')
    } else if (status === 'closing') {
      setStatus('closed')
    }
  }
  // useDrag, useWheel
  // calculate between offset original / fullscreen Pos
  const [ref, dimensions] = useDimensions()
  const originalPosition = getPositionFromBoundingRect(dimensions)

  const wheelBind = useWheel(
    ({ movement: [, y], xy: [, offsetY], wheeling }) => {
      const maxOffset = -100
      if (ref.current && ref.current.scrollY === 0) {
        if (offsetY <= maxOffset) {
          close()
        }
      }
    },
    {
      domTarget: window,
      enabled: status === 'open',
      eventOptions: { passive: false },
    },
  )
  React.useEffect(wheelBind, [wheelBind, status])
  // Maintain document flow
  const closedChildren = props.children({ open, close, status: 'closed' })
  const children = props.children({ open, close, status })

  return (
    <div
      ref={ref}
      style={{
        opacity: status === 'closed' ? 1 : 0,
        ...props.style,
      }}
      className={props.className}
    >
      {closedChildren}
      <Portal>
        {showExpanded && (
          <AnimatedDiv
            style={{
              position: 'fixed',
              zIndex: stackingPriority[status],
            }}
            from={['opening', 'open'].includes(status) ? originalPosition : fullScreenPosition}
            to={['closing', 'closed'].includes(status) ? originalPosition : fullScreenPosition}
            onRest={handleRest}
          >
            {children}
          </AnimatedDiv>
        )}
      </Portal>
    </div>
  )
}

interface Position {
  left: number | string
  width: number | string
  top: number | string
  height: number | string
}

type AnimatedDivProps = {
  from: Position
  to: Position
  onRest: any // onRest
  config?: SpringConfig
  style?: any
}

const AnimatedDiv: React.FC<AnimatedDivProps> = (props) => {
  const animation = useSpring({
    from: props.from,
    to: props.to,
    onRest: props.onRest,
    config: { mass: 1, tension: 180, friction: 27, clamp: true },
  })

  return <animated.div style={{ ...props.style, ...animation }}>{props.children}</animated.div>
}

export type { State }

export default Fullscreen
