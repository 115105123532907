import { gql } from '@apollo/client'

export const GET_ALL_PROJECTS = gql`
  query GetAllProjects($locale: SiteLocale, $anyIn: [ItemId]) {
    allProjects(orderBy: _updatedAt_ASC, filter: { categories: { anyIn: $anyIn } }) {
      id
      title
      client(locale: $locale)
      _status
      _firstPublishedAt
      createdAt
      mainVideo {
        url
        title
        width
        height
      }
      updatedAt
      posterImage {
        url
        alt
        width
        height
      }
      behindTheSceneTitle(locale: $locale)
      categories {
        title
        titleSlug
        id
      }
    }
    _allProjectsMeta {
      count
    }
  }
`

export const GET_PROJECT_DETAIL = gql`
  query GetProjectDetail($id: ItemId, $locale: SiteLocale = en) {
    project(filter: { id: { eq: $id } }, locale: $locale) {
      client
      categories {
        id
        title
        titleSlug
      }
      behindTheSceneTitle(locale: $locale)
      behindTheScenePhotos {
        id
        width
        height
        size
        title(locale: $locale)
        url
        customData
      }
      title(locale: $locale)
      posterImage {
        title(locale: $locale)
        url
      }
      content(locale: $locale) {
        value
      }
      mainVideo {
        url
        title
        width
        height
      }
      credits
    }
  }
`

export const GET_REEL = gql`
  query GetReel {
    reel {
      id
      mobileVideo
      desktopVideo
    }
  }
`

export const GET_NEWS = gql`
  query GetNews($locale: SiteLocale) {
    news(locale: $locale) {
      content(locale: $locale, markdown: false)
      updatedAt
      id
    }
  }
`

export const GET_ABOUT = gql`
  query GetAbout($locale: SiteLocale) {
    about(locale: $locale) {
      id
      updatedAt
      content(locale: $locale, markdown: false)
    }
  }
`

export const GET_CONTACT = gql`
  query GetContact {
    contact {
      email
      address
      personName
      phoneNumber
      role
    }
  }
`

export const GET_CATEGORIES = gql`
  query GetCategories {
    allCategories {
      id
      title
      titleSlug
    }
  }
`

export const GET_PRINTS = gql`
  query GetPrints {
    allPrints {
      title
      id
      poster {
        url
        width
        height
      }
    }
  }
`
