import React, { useState } from 'react'
import { useInterval } from '../../hooks'
import './style.scss'

export const Splash: React.FC = () => {
  const [isShowing, setIsShowing] = useState(true)
  useInterval(() => setIsShowing(false), 1600)
  return (
    <div id="splash" className={isShowing ? 'appear' : 'dissapear'}>
      <video id="loading" autoPlay playsInline muted loop>
        <source src="https://children-of.sgp1.digitaloceanspaces.com/anim/CO_LOGOAnim_400x140.webm" />
        <source src="https://children-of.sgp1.cdn.digitaloceanspaces.com/anim/Logo_2.mp4" />
      </video>
    </div>
  )
}
