import React, { createContext, useEffect, useState, useContext } from 'react'

type ViewportCtx = {
  width: number
  height: number
}

const ViewportContext = createContext<ViewportCtx>({ width: 0, height: 0 })

export const ViewportProvider = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)

  const handleWindowResize = (): void => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize)
    return (): void => window.removeEventListener('resize', handleWindowResize)
  })

  return <ViewportContext.Provider value={{ width, height }}>{children}</ViewportContext.Provider>
}

export const useViewport = (): ViewportCtx => {
  const { width, height } = useContext(ViewportContext)
  return { width, height }
}
