import React from 'react'
import classNames from 'classnames'

type Props = {
  onClick: () => void
  makingOf: boolean
  status: string
}

const BackButton: React.FC<Props> = ({ onClick, makingOf, status }) => {
  return (
    <div className="back-container">
      <button
        className={classNames('back', status, {
          appear: !makingOf,
        })}
        onClick={onClick}
        onTouchEnd={onClick}
      >
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          viewBox="0 0 18 18"
          style={{ width: 20, height: 20 }}
          className="back-svg"
        >
          <path className="st0" d="M0,0h18v18H0V0z" />
          <polygon className="st1" points="0,9 7.6,1.4 9.2,2.9 3.1,9 9.1,15.1 7.6,16.6 " />
        </svg>
      </button>
    </div>
  )
}

export default BackButton
