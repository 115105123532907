import React, { Fragment } from 'react'
import { format } from 'date-fns'
import { useQuery } from '@apollo/client'
import { GET_NEWS } from '../../data/queries'
import { useLocale } from '@react-aria/i18n'
import { GetNews } from '../../data/__generated__/GetNews'

const strToLinkGroup = (str: string): React.ReactNode[] => {
  const matchWholeGroup = /(\[.+\]\(.+\))/gi
  const matchElement = /\[(.+)\]\((.+)\)/i
  const returns = str.split(matchWholeGroup).map((item, id) => {
    const matches = item.match(matchElement)
    if (!matches) return item
    return (
      <a key={id} href={matches[2]} style={{ color: '#e6e6e6' }}>
        {matches[1]}
      </a>
    )
  })
  return returns
}

export const Latest: React.FC = () => {
  const { locale } = useLocale()
  const { data: newsData } = useQuery<GetNews>(GET_NEWS, { variables: { locale: locale.substring(0, 2) } })
  const date = newsData && newsData.news ? new Date(newsData.news.updatedAt) : new Date()
  let strDate = ''
  const formatStr = locale === 'vn' ? "d 'Tháng 'M" : 'MMMM d'
  if (!isNaN(date.getTime())) strDate = format(date, formatStr)
  return (
    <Fragment>
      <h2 style={{ textAlign: 'left', fontSize: '17px' }}>{strDate}</h2>
      {newsData &&
        newsData.news &&
        newsData.news.content.split('\n').map((paragraph, id) => (
          <h1 key={id} style={{ textAlign: 'left', marginTop: '0.5rem', hyphens: 'none' }}>
            {strToLinkGroup(paragraph)}
          </h1>
        ))}
    </Fragment>
  )
}
