/* eslint-disable @typescript-eslint/camelcase */
import axios from 'axios'

const instance = axios.create({
  baseURL: 'https://api.vimeo.com/',
  headers: {
    Authorization: 'Bearer 9008e39bd588b3846ecdbe03697702e7',
  },
})

export const getVideo = (videoId) => instance.get(`/videos/${videoId}`)

export type BtsPhoto = {
  vertical: boolean
  isHighlight: boolean
  url: string
  width: number
  height: number
}

export type Category = {
  name: string
  slug: string
  name_vn: string
}

export type Project = {
  id: number
  video_link: string
  title: string
  client: string
  bts_title: string
  content: string
  poster: {
    url: string
  }
  btsPhotos: BtsPhoto[]
  btsPhotoHighlight: BtsPhoto[]
  credits: string
  categories: Category[]
  creditObj: Record<string, string[]>[]
  created_at: string
}

export type Reel = {
  desktop_link: string
  mobile_link: string
}
export type News = {
  content: string
  content_vn: string
  updated_at: string
}
export type Contact = {
  updated_at: string
  person_name: string
  phone_number: string
  Email: string
  Address: string
  Role: string
}
export type About = {
  updated_at: string
  Content: string
  content_vn: string
}
export type Prints = {
  Photos: { url: string }[]
}
export type Singles = {
  reel: Reel
  new: News
  contact: Contact
  about: About
  print: Prints
  categories: CategoryName[]
}
export type CategoryName = {
  slug: string
  name_vn: string
  name: string
}

export const unwrapCredits = (str: string): Record<string, string[]>[] => {
  if (!str) return []
  if (!str.includes(';')) return []
  const result = str.split(';;').map((group) => {
    return group.split(';').reduce((prev, line) => {
      if (!line.includes(':')) return prev
      const [role, names] = line.split(':')
      if (!names.includes(',')) {
        return {
          ...prev,
          [role]: names,
        }
      }
      const nameArray = names.split(',')
      return {
        ...prev,
        [role]: nameArray,
      }
    }, {})
  })
  return result
}
