import React, { Fragment } from 'react'
import facebook from '../../assets/img/facebook.svg'
import instagram from '../../assets/img/instagram.svg'
import vimeo from '../../assets/img/vimeo.svg'
import { useQuery } from '@apollo/client'
import { GET_ABOUT } from '../../data/queries'
import { useLocale } from '@react-aria/i18n'
import { GetAbout } from '../../data/__generated__/GetAbout'

export const About: React.FC = () => {
  const { locale } = useLocale()
  const { data: contentData } = useQuery<GetAbout>(GET_ABOUT, { variables: { locale: locale.substring(0, 2) } })
  return (
    <Fragment>
      {contentData &&
        contentData.about &&
        contentData.about.content.split('\n').map((paragraph, id) => (
          <h1 key={id} style={{ textAlign: 'left', marginTop: 0 }}>
            {paragraph}
          </h1>
        ))}
      <div className="media">
        <a href="https://www.facebook.com/thechildrenof/" className="no-underline">
          <span className="facebook" style={{ display: 'flex' }}>
            <img src={facebook} />
            <h2 style={{ marginLeft: '0.5rem', fontSize: '17px', textDecoration: 'none' }}>Facebook</h2>
          </span>
        </a>
        <a href="https://www.instagram.com/the_childrenof/" className="no-underline">
          <span className="instagram" style={{ display: 'flex' }}>
            <img src={instagram} style={{ marginLeft: '0.5rem' }} />
            <h2 style={{ marginLeft: '0.5rem', fontSize: '17px', textDecoration: 'none' }}>Instagram</h2>
          </span>
        </a>
        <a href="https://vimeo.com/childrenof" className="no-underline">
          <span className="vimeo" style={{ display: 'flex' }}>
            <img src={vimeo} style={{ marginLeft: '0.5rem' }} />
            <h2 style={{ marginLeft: '0.5rem', fontSize: '17px', textDecoration: 'none' }}>Vimeo</h2>
          </span>
        </a>
      </div>
    </Fragment>
  )
}
