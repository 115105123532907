import React, { useEffect, useRef } from 'react'
import { useWheel, useDrag } from 'react-use-gesture'
import { useSpring } from 'react-spring'
import { VideoPlayer } from '../'
import { useQuery, useReactiveVar } from '@apollo/client'
import { GET_REEL } from '../../data/queries'
import { isShowReelState, isPlayingState } from '../../data/local'
import { useViewport } from '../../hooks/ViewportContext'

const Reel: React.FC = () => {
  const videoRef = useRef()
  const isShowingReel = useReactiveVar(isShowReelState)
  const { width, height } = useViewport()
  const { data: reelData } = useQuery(GET_REEL)

  const [spring, setSpring] = useSpring(
    () => ({
      from: { width, height, opacity: 1, scaleX: 1, scaleY: 1, translateY: 0 },
    }),
    [width, height],
  )
  const dismissReel = (): void => {
    isShowReelState(false)
    isPlayingState(false)
  }
  // wheel
  const bindWheel = useWheel(
    ({ wheeling, movement: [, y], event }) => {
      if (!isShowingReel) return
      event.preventDefault()
      const maxOffset = -150
      const scaleAmount = spring.scaleX.get() - Math.abs(y / (maxOffset * 4))
      const translateY = (1 - y / maxOffset) * maxOffset
      if (!wheeling) {
        return setSpring({ scaleY: 1, scaleX: 1, translateY: 0, opacity: 1 })
      }
      if (Math.abs(y) > Math.abs(maxOffset) / 2) {
        return setSpring({
          opacity: -0.5,
          scaleX: scaleAmount,
          scaleY: scaleAmount,
          onChange: () => {
            if (spring.opacity.get() < 0.5) dismissReel()
          },
        })
      }
      if (y < 0) {
        return setSpring({ scaleY: scaleAmount, scaleX: scaleAmount, translateY, opacity: scaleAmount })
      }
      if (scaleAmount < 1 && scaleAmount > -1) {
        return setSpring({ scaleY: scaleAmount, scaleX: scaleAmount, translateY, opacity: scaleAmount })
      }
    },
    {
      domTarget: window,
      eventOptions: { passive: false },
      enabled: isShowingReel,
    },
  )
  useEffect(bindWheel, [bindWheel])
  // drag
  const drag = useDrag(
    ({ dragging, movement: [, y], event }) => {
      if (!isShowingReel) return
      event.preventDefault()
      const maxOffset = -100 - window.innerHeight / 6
      const scaleAmount = spring.scaleX.get() - Math.abs(y / (maxOffset * 2))
      const translateY = (1 - y / maxOffset) * maxOffset
      if (!dragging) {
        return setSpring({ scaleY: 1, scaleX: 1, translateY: 0, opacity: 1 })
      }
      if (Math.abs(y) > Math.abs(maxOffset) / 2) {
        return setSpring({
          opacity: -0.5,
          scaleX: scaleAmount,
          scaleY: scaleAmount,
          onChange: () => {
            if (spring.opacity.get() < 0.5) dismissReel()
          },
        })
      }
      if (y < 0) {
        return setSpring({ translateY })
      }
      if (scaleAmount < 1 && scaleAmount > -1) {
        return setSpring({ translateY })
      }
    },
    {
      domTarget: window,
      eventOptions: { passive: false },
      enabled: isShowingReel,
    },
  )
  useEffect(drag, [drag])
  if (!reelData || !reelData.reel || !isShowingReel) return null
  return (
    <VideoPlayer
      defaultlink={reelData.reel.desktopVideo}
      mobilelink={reelData.reel.mobileVideo}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'black',
        zIndex: 9998,
        objectFit: 'cover',
        ...spring,
      }}
      onEnded={dismissReel}
      controls={false}
      playbutton
      reel
      autoPlay={false}
      ref={videoRef}
    />
  )
}

export default Reel
