import React from 'react'
import { NotFound, Splash, MenuButton } from './components'
import Home from './containers/Home'
import Work from './containers/Work'
import { withTracker } from './withTracker'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { client } from './data/apollo'
import { ViewportProvider } from './hooks/ViewportContext'

const App: React.FC = () => (
  <BrowserRouter basename="base">
    <div className="app">
      <Splash />
      <ViewportProvider>
        <ApolloProvider client={client}>
          <MenuButton />
          <Switch>
            <Route path="/works/:id(\d+)" component={withTracker(Work)} />
            <Route exact path="/" component={withTracker(Home)} />
            <Route component={NotFound} />
          </Switch>
        </ApolloProvider>
      </ViewportProvider>
    </div>
  </BrowserRouter>
)

export default App
