window.matchMedia =
  window.matchMedia ||
  function () {
    return {
      matches: false,
      addListener: () => {
        /** donothing */
      },
      removeListener: () => {
        /** donothing */
      },
    }
  }
