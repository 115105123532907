import React, { memo, ReactElement, ReactNode } from 'react'
import { useSpring, animated } from 'react-spring'
import { useMeasure, usePrevious } from '../../hooks'
import { Content, Title } from './styles'

type Props = {
  name: ReactElement | string
  style?: React.CSSProperties
  isOpen: boolean
  children?: ReactNode
  className?: string
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onTouchEnd?: (e: React.TouchEvent<HTMLDivElement>) => void
}

const Tree: React.FC<Props> = ({ children, name, style, className = '', onClick = undefined, isOpen, onTouchEnd }) => {
  const previous = usePrevious(isOpen)
  const [bind, { height: viewHeight }] = useMeasure()
  const config = { mass: 1, tension: 212, friction: 50, precision: 0.0001, clamp: true }
  const spring = useSpring({
    config,
    from: { height: 0, opacity: 0, transform: 'translate3d(0,20p,0)' },
    to: {
      height: isOpen ? viewHeight : 0,
      opacity: isOpen ? 1 : 0,
      transform: `translate3d(0,${isOpen ? 0 : 20}px,0)`,
      padding: isOpen ? '1.3rem 1rem' : '0rem 0rem',
    },
  })
  let topMenu: HTMLElement = null
  const topMenuProps = {
    ref: (el): void => (topMenu = el),
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      const y = e.clientY - topMenu.getBoundingClientRect().top
      if (y < 56) {
        return onClick(e)
      }
    },
    onTouchEnd: (e: React.TouchEvent<HTMLDivElement>): void => {
      if (!onTouchEnd) return
      const touchTarget = e.changedTouches[0]
      const y = touchTarget.clientY - topMenu.getBoundingClientRect().top
      if (y < 56) {
        return onTouchEnd(e)
      }
    },
  }
  return (
    <div style={style} className={className} {...topMenuProps}>
      <Title style={{ textAlign: 'center' }}>{name}</Title>
      <Content
        style={{
          opacity: spring['opacity'],
          padding: 0,
          height: isOpen && previous === isOpen ? 'auto' : spring['height'],
        }}
      >
        <animated.div className="menu-content" style={{ transform: spring['transform'] }} {...bind}>
          {children}
        </animated.div>
      </Content>
    </div>
  )
}

export default memo(Tree)
