/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect } from 'react'
import { useSpring, animated } from 'react-spring'
import Tree from './Tree'
import Subtree from './Subtree'
import ThreeDots from './ThreeDots'
import './style.scss'
//
import Logo from '../../assets/img/Children-of-logo-white.svg'
import { About } from './About'
import { Latest } from './Latest'
import { Contact } from './Contact'
import './style.scss'
import { isShowReelState, isPlayingState } from '../../data/local'
import { useLocale } from '@react-aria/i18n'
import { useReactiveVar } from '@apollo/client'

const MainButton = (isOpen: boolean, isPlaying: boolean): JSX.Element => {
  const spring = useSpring({
    config: { mass: 1, tension: 280, friction: 60 },
    from: { height: '40px', margin: '8px calc(50% - 7rem) 7px' },
    to: { height: isOpen ? '42px' : '40px', margin: isOpen ? '8px calc(50% - 7rem) 7px' : '9px calc(50% - 7rem) 7px' },
  })
  return (
    <div className="menu one flex row">
      <ThreeDots isExpanded={isOpen} isPlaying={isPlaying} />
      <animated.img src={Logo} alt="children-of-logo" style={spring} className="styled-logo" />
    </div>
  )
}

type Menu = 'latest' | 'about' | 'contact'

const title = {
  'vi-VN': {
    contact: 'Liên Hệ',
    about: 'Chúng Tôi',
    latest: 'Tin Mới Nhất',
  },
  'en-US': {
    contact: 'Contact',
    about: 'About',
    latest: 'Latest',
  },
}

const MenuButton: React.FC = () => {
  let { locale } = useLocale()
  if (!title[locale]) {
    locale = 'en-US'
  }
  const [isOpen, setOpen] = useState<boolean>(false)
  const [selectedMenu, setSelected] = useState<Menu>('latest')
  const isPlaying = useReactiveVar(isPlayingState)
  // shrink on click outside
  useEffect(() => {
    const onClick = (e: MouseEvent | TouchEvent): void => {
      const target = e.target as HTMLElement
      const isIncludedInMenu = !!target.closest('.menu')
      if (isOpen && !isIncludedInMenu) {
        setOpen(false)
      }
    }
    document.addEventListener('click', onClick)
    return (): void => {
      document.removeEventListener('click', onClick)
    }
  }, [isOpen])
  // shrink on scroll
  useEffect(() => {
    const handleScroll = (): void => {
      setOpen(false)
    }
    window.addEventListener('scroll', handleScroll)
    return (): void => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  useEffect(() => {
    if (isPlaying) setOpen(false)
  }, [])
  const toggleOpenAction = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement>,
  ): void => {
    e.stopPropagation()
    setOpen(!isOpen)
  }
  return (
    <Tree
      name={MainButton(isOpen, isPlaying)}
      className={`main-menu menu flex col ${isPlaying && !isOpen ? 'transparent' : ''}`}
      onClick={toggleOpenAction}
      onTouchEnd={!isShowReelState() && toggleOpenAction}
      isOpen={isOpen}
    >
      <Subtree
        name={title[locale].latest}
        isOpen={selectedMenu === 'latest'}
        onClick={(): void => setSelected('latest')}
      >
        <Latest />
      </Subtree>
      <Subtree
        name={title[locale].about}
        className={`top-separator ${selectedMenu === 'about' ? 'light-bg' : ''}`}
        isOpen={selectedMenu === 'about'}
        onClick={(): void => setSelected('about')}
      >
        <About />
      </Subtree>
      <Subtree
        name={title[locale].contact}
        className={`top-separator ${selectedMenu === 'contact' ? 'light-bg' : ''}`}
        isOpen={selectedMenu === 'contact'}
        onClick={(): void => setSelected('contact')}
        style={{ borderRadius: '0px 0.25rem 0.25rem' }}
      >
        <Contact />
      </Subtree>
    </Tree>
  )
}

export default MenuButton
