import React from 'react'
import { animated } from 'react-spring'

const Title = ({ children, style }) => {
  return (
    <animated.div style={style} className="menu menu-title">
      {children}
    </animated.div>
  )
}

const Content = ({ children, style }) => (
  <animated.div style={style} className="menu-content">
    {children}
  </animated.div>
)

export { Content, Title }
