import React, { Fragment } from 'react'
import { useQuery } from '@apollo/client'
import { GET_CONTACT } from '../../data/queries'
import { GetContact } from '../../data/__generated__/GetContact'

export const Contact: React.FC = () => {
  const { data: contactData } = useQuery<GetContact>(GET_CONTACT)
  if (!contactData || !contactData.contact) return null
  return (
    <Fragment>
      <h1 style={{ textAlign: 'left', marginTop: 0, marginBottom: 0 }}>{contactData.contact.personName}</h1>
      <h2 style={{ textAlign: 'left' }}>{contactData.contact.role}</h2>
      <h1 style={{ textAlign: 'left', marginTop: '1rem', lineHeight: 1.4 }}>
        <a href={`tel:${contactData.contact.phoneNumber}`} style={{ textDecoration: 'none' }}>
          {contactData.contact.phoneNumber}
        </a>
        <br />
        {contactData.contact.email}
        <br />
        {contactData.contact.address}
        <br />
      </h1>
    </Fragment>
  )
}
