import React from 'react'
import { a } from 'react-spring'

type Props = {
  onClick: (e: any) => void
  makingOf: boolean
  style?: any
}

const PillButton: React.FC<Props> = ({ onClick, makingOf, style }) => {
  const text = makingOf ? 'Close' : 'Making Of'
  return (
    <a.div className="pill-container" style={style}>
      <button className={`pill ${!makingOf ? 'making-of' : 'close'}`} onClick={onClick}>
        <h4>{text}</h4>
        <div className="shine" />
      </button>
    </a.div>
  )
}

export default PillButton
